<template>
    <div class="judges-bg">
      <div class="judges-content">
        <p class="icon"></p>
        <p class="tit">{{ $t("Judges.tit") }}</p>
        <p class="tip">{{ $t("Judges.tip_2") }}</p>
        <br /><br />
        <p class="txt">{{ $t("Judges.txt") }}</p>
      </div>
      <div class="judges-img">
        <div class="picture" v-for="(item, index) in cardLists" :key="index">
          <div
            :class="'img-item' + (index + 1)"
            :style="{
              'background-image': `url('  ${item.img}  ')`,
              'background-size': 'cover',
              'background-repeat': 'no-repeat',
              'background-position': 'top center',
              height: '200px',
              width: '200px',
              flex: '1',
            }"
          ></div>
          <div class="name_title">
            <div class="txt">
              <p style="color: #1e30f6; font-size: 18px">
                {{ $t("Judges." + item.name) }}
              </p>
              <p style="color: #6ec9f5; margin-top: 5px" v-html="$t('Judges.' + item.txt)">
              </p>
              <p style="color: #707070; margin-top: 20px; font-size: 14px;width:90%" v-html="$t('Judges.' + item.detail)">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        cardLists: [
          {
            name: "new_name1",
            txt: "new_txt1",
            img: require("../assets/images/judges2023/Cecilia Chan.webp"),
            detail: "new_de1",
          },
          {
            name: "new_name2",
            txt: "new_txt2",
            img: require("../assets/images/judges2023/Sophia Chan.webp"),
            detail: "new_de2",
          },
          {
            name: "new_name3",
            txt: "new_txt3",
            img: require("../assets/images/judges2023/Alan Cheung.jpg"),
            detail: "new_de3",
          },
          {
            name: "new_name4",
            txt: "new_txt4",
            img: require("../assets/images/judges2023/Fung Hong.jpg"),
            detail: "new_de4",
          },
          {
            name: "new_name5",
            txt: "new_txt5",
            img: require("../assets/images/judges2023/CC Lam.webp"),
            detail: "new_de5",
          },
          {
            name: "new_name6",
            txt: "new_txt6",
            img: require("../assets/images/judges2023/Trisha.jpg"),
            detail: "new_de6",
          },
          {
            name: "new_name7",
            txt: "new_txt7",
            img: require("../assets/images/judges2023/Gilbert Lee Pro Pic.jpg"),
            detail: "new_de7",
          },
          {
            name: "new_name8",
            txt: "new_txt8",
            img: require("../assets/images/judges2023/Dr Lo Photo 1.webp"),
            detail: "new_de8",
          },
          {
            name: "new_name9",
            txt: "new_txt9",
            img: require("../assets/images/judges2023/Kris Lui.webp"),
            detail: "new_de9",
          },
          {
            name: "new_name10",
            txt: "new_txt10",
            img: require("../assets/images/judges2023/joe.webp"),
            detail: "new_de10",
          },
          {
            name: "new_name11",
            txt: "new_txt11",
            img: require("../assets/images/judges2023/jenny.webp"),
            detail: "new_de11",
          },
          {
            name: "new_name12",
            txt: "new_txt12",
            img: require("../assets/images/judges2023/Jim Thompson.webp"),
            detail: "new_de12",
          },
          {
            name: "new_name13",
            txt: "new_txt13",
            img: require("../assets/images/judges2023/Patrick Tsang.webp"),
            detail: "new_de13",
          },
        ],
      };
    },
    created() {},
  };
  </script>
  
  <style scoped lang="scss">
  .judges-bg {
    .judges-content {
      .icon {
        width: 40px;
        height: 40px;
        margin: 40px auto 5px;
        background: url("../assets/images/Group 11846.svg") 50%/50% no-repeat;
      }
      .tit {
        font-size: 26px;
        color: #1e30f6;
      font-family: 'PingFang-Heavy';
        letter-spacing: 2px;
        line-height: 24px;
      }
      .tip {
        margin: 20px auto 0px;
        line-height: 28px;
        width: 54%;
        color: #707070;
      }
      .txt {
        margin: 0px auto;
        width: 250px;
        color: #707070;
        font-size: 14px;
        border-bottom: 1px solid #6ec9f5;
        letter-spacing: 1px;
            font-family: 'PingFang-Light';
      }
    }
    .judges-img {
      width: 70%;
      margin: 60px auto;
      // background: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row wrap;
      .picture {
        width: 48%;
        margin-bottom: 3%;
        display: inline-block;
        vertical-align: top;
        display: flex;
        box-shadow: #efecef 2px 2px 8px 2px;
      }
      // // .img-item1 {
      // //   background-size: 120% !important;
      // //   background-position: 55% 30% !important;
      // // }
      .img-item4 {
        background-size: 120% !important;
        background-position: 80% 40% !important;
      }
      // .img-item6 {
      //   background-size: 180% !important;
      //   background-position: 0% 35% !important;
      // }
      //     .img-item9 {
      //       background-size: 120% !important;
      //   background-position: 30% 50% !important;
      // }
      // .img-item10 {
      //   background-size: 180% !important;
      //   background-position: 50% 10% !important;
      // }
      .name_title {
        text-align: left;
        flex: 2;
        // background: #2090c4;
        color: #fff;
        .txt {
          padding: 10px 0px 0px 28px;
        }
      }
    }
  }
  @media screen and (max-width:520px) {
    .judges-bg{
      .judges-content{
        .tip{
              width: 90%;
        }
      }
      .judges-img{
        width: 90%;
        flex-direction: column;
        .picture{
          width: 100%;
              margin-bottom: 8%;
        }
      }
    }
  }
  </style>