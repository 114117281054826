var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"judges-bg"},[_c('div',{staticClass:"judges-content"},[_c('p',{staticClass:"icon"}),_c('p',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t("Judges.tit")))]),_c('p',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t("Judges.tip_2")))]),_c('br'),_c('br'),_c('p',{staticClass:"txt"},[_vm._v(_vm._s(_vm.$t("Judges.txt")))])]),_c('div',{staticClass:"judges-img"},_vm._l((_vm.cardLists),function(item,index){return _c('div',{key:index,staticClass:"picture"},[_c('div',{class:'img-item' + (index + 1),style:({
          'background-image': `url('  ${item.img}  ')`,
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'top center',
          height: '200px',
          width: '200px',
          flex: '1',
        })}),_c('div',{staticClass:"name_title"},[_c('div',{staticClass:"txt"},[_c('p',{staticStyle:{"color":"#1e30f6","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.$t("Judges." + item.name))+" ")]),_c('p',{staticStyle:{"color":"#6ec9f5","margin-top":"5px"},domProps:{"innerHTML":_vm._s(_vm.$t('Judges.' + item.txt))}}),_c('p',{staticStyle:{"color":"#707070","margin-top":"20px","font-size":"14px","width":"90%"},domProps:{"innerHTML":_vm._s(_vm.$t('Judges.' + item.detail))}})])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }